import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'portalninasyninos',
    loadChildren: () => import('./portal-ninas-y-ninos/portal-ninas-y-ninos.module').then(m => m.PortalNinasYNinosModule)
  },
  {
    path: 'portaldocentes',
    loadChildren: () => import('./portal-docentes/portal-docentes.module').then(m => m.PotalDocentesModule)
  },
  {
    path: 'diplomado',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: '**',
    redirectTo: 'portalninasyninos/videojuegos'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
